<template>
      
  <div class="zhanList">
    <!-- <aside class="action">
      <span @click="$router.push('/activity')">博物活动</span>  &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;博物活动
    </aside> -->
    <div class="marginleft">
      <mainBox></mainBox>

    </div>
    
    <div class="list">
      <ul class="listed">
        <li :class="quans === '0' ? 'actives' : 'null'" @click="activ('0')">
          全部
        </li>
        <li :class="quans === '1' ? 'actives' : 'null'" @click="activ('1')">
          未开始
        </li>
        <li :class="quans === '2' ? 'actives' : 'null'" @click="activ('2')">
          进行中
        </li>
        <li :class="quans === '3' ? 'actives' : 'null'" @click="activ('3')">
          已结束
        </li>
      </ul>
    </div>
    <!-- 搜索框 -->
    <aside class="action2">
      <div class="dev">
        <div class="inputs">
          <input type="text" v-model="value" />
          <div class="btn" @click="sreach"></div>
        </div>
      </div>
    </aside>
    <!-- 列表内容 -->
    <div class="imageList" v-if="outList.length !=0">
      <div v-for="(item, key) in outList" :key="key">
        <!-- <img
          :src="require('../assets/img/book/' + item.ceimg)"
          alt=""
          class="leftImg"
        /> -->
        <div  class="imgs" :class="key%3 ==2 ? 'mar' : 'may'">
          <div class="imggoods">
        <img
          @click="$router.push(`/activitydetail?id=${item.id}`)"
          :src="item.images"
          alt=""
          class="rightImg"
        /></div>
        <div class="tits">
          <!-- 标题 -->
          <div class="Titlefts" @mousedown.stop="an" @mouseup.stop="song(item.id)">
            {{ item.name }}
          </div>
          <!-- 五角心 -->
          <div
            class="Titright"
            v-if="item.like_status == '2' || !token"
            @click="shoucang(key)"
          >
            <img src="../assets/collect2.png" alt="" />
          </div>
          <div
            class="Titright"
            v-if="item.like_status == 1 && token"
            @click="shoucang(key)"
          >
            <img src="../assets/sc.png" alt="" />
          </div>
        </div>
        <div class="det">
          {{ item.introduce }}
        </div>
        <div class="times">
          <div class="Titleft">
            活动时间:
            <div class="ti">
              {{ item.start_time  |time}}至{{ item.end_time |time}}
              <!-- {{item}} -->
            </div>
          </div>
        </div>
        <div class="shengyu" v-if="item.hdstatus != '已结束'">
          <span class="shenyu">剩余票数: </span>
          <span class="number">{{item.yupiao }}</span>
          <span class="zhang"> 张</span>
         
        </div>
        <div
          class="tou"
          :class="item.hdstatus == '进行中' ? 'jinxing' : ''"
          v-if="item.hdstatus == '进行中'"
          @click="$router.push(`/activitydetail?id=${item.id}`)"
        >
          {{ item.hdstatus }}
        </div>
        <div
          class="tou"
          :class="item.hdstatus == '未开始' ? 'noOk' : ''"
          v-if="item.hdstatus == '未开始'"
          @click="$router.push(`/activitydetail?id=${item.id}`)"
        >
          {{ item.hdstatus }}
        </div>
        <div
          class="tou"
          :class="item.hdstatus == '已结束' ? 'isOk' : ''"
          v-if="item.hdstatus == '已结束'"
          @click="$router.push(`/activitydetail?id=${item.id}`)"
        >
          {{ item.hdstatus }}
        </div>
        </div>

      </div>
    </div>
    <div class="imageList" v-else>
        暂无匹配项~
    </div>
    <div class="page">
      <!-- <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next, total"
        :total="activs"
        :page-size="9"
      >
      </el-pagination> -->
      <!-- {{}} -->
      <Pagetion  :total="activs" :pageSize="9" @ClickChange="handleCurrentChange"></Pagetion>
    </div>

        <Footer></Footer>
  </div>
</template>

<script>
import { fetch, post } from '../api/https';
import Footer from "../components/footer";
import detailImg from "../assets/detailImg.png";
import Pagetion from '../components/pagetion/pagetion.vue';
import mainBox from "@/components/main.vue";
import moment from 'moment'
export default {
  data() {
    return {
      quans: "0",
      outList: [],
      page: 1,
      value: "",
      status: 0,
      activs: 1,
      token:localStorage.getItem('token'),
            timeLength:0,
      oldtime:0,
    };
  },
  components: {
    Footer,
    Pagetion,
    mainBox
  },
  created() {},
  mounted() {
    this.requstOut();
    document.title ='活动列表'
  },
  filters:{
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
    }
  },
  methods: {
        an(){ //鼠标按下
       this.timeLength = new Date().getTime()
    },
    song(id){ //鼠标松开 判断鼠标点击的时间
       this.oldtime = new Date().getTime()
       if((this.oldtime-this.timeLength) <500){
                 this.$router.push(`/deta?id=${id}`)
       }
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requstOut();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    sreach() {
      this.activs = 1;
      this.status = 0;
      this.page = 1;
      this.requstOut();
    },
    activ(val) {
      this.quans = val;
      this.status = val;
      this.page = 1;
      this.value = "";
      this.requstOut();
    },
    requstOut() {
      fetch("/activity_list", {
        cate_id: 1,
        status: this.status,
        page: this.page,
        pagesize: 9,
        name: this.value,
        source: "pc",
        token:localStorage.getItem("token") || ''
      })
        .then((res) => {
          this.outList = res.data.data;
          this.activs = res.data.total_count;
          this.outList.forEach((item, key) => {
            var num = (key % 8) + 1;
            var str = `${num}.png`;
            item.ceimg = str;
            });
            this.outList.forEach((item,key)=>{
                item.create_time = moment(1000*parseInt(item.create_time)).format("YYYY-MM-DD")
              })
        })
        .catch((err) => {});
    },
        shoucang(key){
      if(localStorage.getItem('token')){
        var obj = {
              token:localStorage.getItem('token'),
              source:'pc',
              source: "pc",
              id:this.outList[key].id
        }
          if(this.outList[key].like_status == 1){
                  this.outList[key].like_status = 2
                  //调用收藏接口 ========== 取消收藏
                  
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
                  }else{
                  this.outList[key].like_status = 1
                  //调用收藏接口 ========== 收藏
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
          }
      }else{
        this.showPopupMessage('请先登录',false)
      }
    },
  },
};
</script>

<style scoped>
.zhanList {
  width: 100%;
  height: auto;
}
.banner {
  width: 100%;
  height: 10px;
}
.banner img {
  width: 0;
  height: 0%;
}
.action {
  display: block;
  width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  box-sizing: border-box;
  margin-top: 49px;
  margin-bottom: 50px;
}
.listed {
  margin: 0 auto;
}
.list {
      /* padding-left: 80px; */
  max-width: 1400px;
  margin: 0 auto;
}
.listed li {
  cursor: pointer;
  height: 42px;
  width: 126px;
  text-align: center;
  line-height: 42px;
  float: left;
  margin-right: 20px;
}
.times {
  font-size: 14px;
  color: #999999;
  padding-left: 4px;
  font-family:'Microsoft YaHei';
  font-weight: 400;
  margin-bottom: 10px;
  height: 14px;
  line-height: 15px;
}
.null {
  background: url("../assets/img/book/uncheck.png");
  background-size: 125px 40px;
  color: #666666;
}
.actives {
  background: url("../assets/img/book/checked.png");
  background-size: 125px 40px;
  color: white;
}
.imageList {
  width: 1400px;
  margin: 0 auto;
  padding-top: 39px;
  height: auto;
  padding-bottom: 30px;
  min-height: 500px;
  clear: both;
}
.imgs {
  float: left;
  position: relative;
  margin-bottom: 30px;
  width: 430px;
  height: 400px;
}
.mar{
  margin-right: 0px;
}
.may{
  margin-right: 47px;

}
.big {
  position: absolute;
  top: 0;
  right: 0;
  width: 446px;
  height: 298px;
}
.rightImg {
  width: 446px;
  height: 298px;
  transition: 1s;
}
.imggoods{
    width: 446px;
  height: 298px;
  overflow: hidden;
}
.rightImg:hover{
  transform:scale(1.1);
}
.leftImgText {
  position: absolute;
  bottom: -100px;
  height: 500px;
  display: block;
  /* color: greenyellow; */
  /* box-sizing: border-box; */
  /* padding-top: 30px; */
  /* padding-left: 35px; */
  /* padding-right: 35px; */
}
.titleName {
  font-size: 20px;
  width: 25px;
  float: right;
}
.detailTitle {
  width: 20px;
  float: left;
  font-size: 15px;
}
.action2 {
  display: block;
  width:1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: auto;
}
.action2 .dev {
  width: 500px;
  float: right;
}
.inputs {
  background: url("../assets/img/book/search.png") no-repeat;
  background-size: 341px 42px;
  width: 341px;

  height: 42px;
  float: right;
  position: relative;
}
.inputs input {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: none;
  padding: 10px;
  padding-left: 15px;
  height: 20px;
  background: none;
  outline: none;
  font-size: 14px;
  width: 80% !important;

}
.btn {
  background: url("../assets/img/book/searchBtn.png");
  background-size: 16px;
  width: 16px;
  height: 16px;
  float: right;
  text-align: center;
  line-height: 40px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 21px;
}
.tou {
  width: 69px;
  height: 26px;
  background-color: #4c733c;
  font-size: 12px;
  text-align: center;
  line-height: 26px;
  color: white;
  position: absolute;
  bottom: 0px;
  right: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.page {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding: 60px 0;
}
el-pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}
.tits {
  width: 100%;
  height: 19px;
  font-size: 18px;
  line-height: 10px;
  position: relative;
  margin-left: 4px;
  margin-top: 14px;
  color: #333333;
}

.titleft {
  float: left;
  margin-left: 4px;
 }
.ti {
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  display: inline-block;
}
.marginleft{
  margin: 0;
}
.Titlefts {
  position: absolute;
  bottom: 0;
  left: 0px;
  height: 19px;
  line-height: 19px;
  color:#333333;
  font-size:'微软雅黑';
  font-size:18px;
  display: block;
  width: 400px;
  overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap
}
.Titlefts:hover{
  cursor: pointer;

}
.shengyu {
  position: absolute;
  bottom: -5px;
  left: 0;
  padding-left: 4px;
  height: 15px;
  font-size: 14px;
  line-height: 15px;
  margin-top: 5px;
}
.shenyu {
  height: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
}
.number {
  line-height: 15px;
  color: red;
}
.zhang {
  color: #999999;
}
.Titright {
  position: absolute;
  right: 4px;
  top: 0;
  width: 60px;
  float: right;
  text-align: right;
}
.Titright img {
  height: 19px;
  width: 19px;
  padding-right: 4px;
}
.jinxing {
  background-color: #c6863b;
  color: white;
}
.noOk {
  background: #eeeeee;
  color: #999999;
}
.isOk {
  background: #eeeeee;
  color: #999999;
}
.Titleft {
  position: absolute;
  left: 4px;
}
.det {
  font-size: 12px;
  color: #999999;
  height: 13px;
  padding-left: 4px;
  margin-top: 10px;
  font-family:'Microsoft YaHei';
  font-weight: 300;
  margin-bottom: 10px;
  width: 90%;
  display: block;
  overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
}
.page {
  width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 58px;
}
el-pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}
@media screen and (max-width: 1440px){
  .may{
    margin-right: 26px;
  }
  .marginleft{
    margin-left: 30px;

  }
  .list{
    width: 1410px;
    padding-left: 35px;
  }
  .imageList{
    padding-left: 20px;
    box-sizing: border-box;

  }
  .dev{
    margin-right: 25px;
  }}


@media screen and (max-width: 1366px){
  .may{
    margin-right: 26px;
  }
  .action2{
    width: 1313px;
  }
  .imageList{
    padding-left: 5px;

  }
    .dev{
    margin-right: -25px;
  }
  .list{
    padding-left: 5px;
  }
  .marginleft{
    margin-left: -80px;
    margin-right: 0;
  }
}
</style>