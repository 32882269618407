<template>
      
  <div>
    <el-pagination
      v-if="this.total !== 0"
      class="pagenation"
      background
      layout="prev, pager, next, total"
      :total="total"
      :page-size="pageSize"
      prev-text="上一页"
      next-text="下一页"
      @current-change="currentChange"
    >
    </el-pagination>
        
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    pageSize: Number,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
  },
  methods: {
    currentChange(res) {
      this.$emit("ClickChange", res);
              let el = document.querySelector('#frame_content_view')
        if (el) {
          el.scrollTop = 0
        }
    },
  },
};
</script>
<style>
.pagenation{
  text-align: center  !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66 !important;
  color: #fff !important;
  min-width: 60px !important;
  height: 40px !important;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff !important;
  cursor: default !important;
  min-width: 60px !important;
  height: 40px !important;
}
.el-pagination.is-background .el-pager li:hover {
  color:#c19c66 !important;
  min-width: 60px !important;
  height: 40px !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color:#c19c66 !important;
  min-width: 60px !important;
  height: 40px !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #c19c66 !important;
  color: #fff !important;
  min-width: 60px !important;
  height: 40px !important;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  height: 40px !important;
  line-height: 40px  !important;
  min-width: 60px  !important;
  height: 40px !important;
}

.el-pagination.is-background .el-pager li {
  border: 1px solid #e6e6e6 !important;
  min-width: 74px !important;
  height: 40px !important;
  line-height: 40px !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
}
.el-pagination.is-background .el-pager li {
  border: 1px solid #e6e6e6 !important;
  min-width: 60px !important;
  height: 40px !important;
  line-height: 40px !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
}
.el-pagination.is-background .btn-prev,.el-pagination.is-background .btn-next{
  width: 74px !important;
  font-size: 14px !important;
}
>>> .el-pagination__total {
  /* margin-top: 6px; */
  margin-left: 5px !important;
  transform: scale(1.19) !important;
  font-weight: 600 !important;
}
</style>
